import React from 'react';

import PageWrap from 'components/PageWrap';
import { Section } from 'components/Section';
import { PagePicTitle } from 'components/PagePicTitle';

import { FeaturePoint } from 'components/FeaturePoint';
import CallsToAction from 'components/CallsToAction';
import { Separator } from 'components/Separator';
import IndyLink from 'components/IndyLink';
import { SEO } from 'components/Helmets';

import ShareIcon from 'media/shared/modIcons/Share.inline.svg';

import userPermImg from 'media/Screenshots/General/UserPermissions.png';
import shareFileImg from 'media/Screenshots/Share/ShareFile.png';
import sharePortalImg from 'media/Screenshots/Share/SharePortal.png';
import fileSizeReport from 'media/Screenshots/Reporting/ShareFileSizeProfile.png';

export default function ModShare({data}){
    const CTATypes = ['SignUp', 'Demo'];
    const currentYear = new Date().getFullYear();
    const pagePath = "/modules/library";

    return (
        <PageWrap noSEO={true} path={pagePath}>
            <SEO
                title="The Share Module - Mashoom"
                description="If you want to share your data with others, this is the module for you."
                previewImgKey="share"
                path={pagePath}
            />
            <PagePicTitle
                imageCmp={ShareIcon}
                imageAlt="Share module icon">
                <h1>The Share Module</h1>
                <p>If you want to share your data with others, this is the module for you.</p>
            </PagePicTitle>
            <FeaturePoint
                title='Enables "External Users"'
                image={userPermImg}
                pageInd="1"
            >
                <p>External users by default have no access to any information on your account. This allows you to assign them individual permissions to your data and / or features, giving you complete control over what they can see and do with your data.</p>
                <p className="paraSpaceTop">If you have a large organization, where it wouldn't appropriate for everyone to see the same information, internal users can be disabled completely to address this use case.</p>
            </FeaturePoint>
            <Separator />
            <FeaturePoint
                title="Send files via email"
                image={shareFileImg}
                pageInd="2"
            >
                <p>Designed to be as simple as possible, any size and quantity of files can be sent to any number of people, via an email download link. This means that you upload the files to Mashoom, then the receiver gets an email with a link allowing them to download those files. The receiver doesn't need to sign up or provide any more information.</p>
                <p className="paraSpaceTop">All download links come with an expiry period you can adjust. This reduces the impact of a mailbox being hacked or exposed, only recent download links can be utilized.</p>
            </FeaturePoint>
            <Separator />
            <FeaturePoint
                title="Receive files from anyone"
                image={sharePortalImg}
                pageInd="3"
            >
                <p>You can send a sharing portal; a link that allows the receiver to upload and send you files back to you in the same way as above. If you allow them, they can even send files to multiple recipients, not just you.</p>
            </FeaturePoint>
            <Separator />
            <FeaturePoint
                title="That's it!"
                image={fileSizeReport}
                pageInd="4"
            >
                <p>It's {currentYear}, this doesn't need to be complicated. This module allows you to send and receive files with people reliably and simply. In both cases you also specify and expiry period to keep you in control of content you have shared.</p>
                <p className="paraSpaceTop">Looking to protect yourself or your team from cyber threats as they send and receive files? Implement this module with <IndyLink adr="/addons">virus scanning</IndyLink> and your problem is solved.</p>
            </FeaturePoint>
            <Section id="FeatureCTA">
                <div id="FeatureCTACont">
                    <h2>Like what your hear?</h2>
                    <CallsToAction types={CTATypes}></CallsToAction>
                </div>
            </Section>
        </PageWrap>
    );
}